import React from "react";
import Hero from "../Hero";
import ArticleGrid from "../ArticleGrid"

export default function Lsao(){
    return(
        <div>
            <Hero page="lsao"/>
            <ArticleGrid/>
        </div>
    )
}